<!-- Documentation links
  
  Documention for bootstrap-vue file input:
  https://bootstrap-vue.org/docs/components/form-file

  Documentation for standard input element, + filters:
  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input 
-->

<template>
  <b-container class="validationmatrixmanagement">
    <b-row>
      <b-col cols="12" md="12" lg="10" offset-lg="1">
        <h2>Email Allowlist</h2>
        <b-button variant="primary" class="mx-1 my-1" @click="showSubmitCSV"
          >Submit Validation Matrix CSV</b-button
        >
        <b-button variant="primary" class="mx-1 my-1" @click="fetchCSV"
        >Fetch current file</b-button
      >
      </b-col>
    </b-row>

    <b-modal
      ref="selectCSVModal"
      title="Submit Validation Matrix CSV"
      hide-header-close
      ok-only
      ok-title="Close"
      ok-variant="secondary"
    >
      <b-file
        id="csvFileDrop"
        v-model="file"
        :state="importCSVFormValid"
        class="big-file-input"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-file>
      <b-button
        class="mx-1 my-1"
        @click="validateCSVFile"
        variant="primary"
        :disabled="!importCSVFormValid"
        >Validate file</b-button
      >
      <b-button
        class="mx-1 my-1"
        @click="submitCSVFile"
        variant="primary"
        :disabled="!importCSVFormValid"
        >Submit file</b-button
      >
    </b-modal>

    <b-modal
      ref="csvSubmitModal"
      title="Importing CSV"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <b-list-group>
        <b-list-group-item
          v-for="stage in submitInfo.stages"
          :key="stage.index"
          class="flex-column justify-content-between"
        >
          <b-icon
            v-if="stage.state == STAGE_STATES.WAITING"
            icon="arrow-clockwise"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.IN_PROGRESS"
            icon="arrow-clockwise"
            variant="primary"
            animation="spin"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.FINISHED"
            icon="check-circle"
            variant="success"
          ></b-icon>
          <b-icon
            v-if="stage.state == STAGE_STATES.ERROR"
            icon="x-circle"
            variant="danger"
          ></b-icon>
          {{ stage.display_text }}
          <b-progress
            v-if="stage.progressTotal"
            :value="stage.progress"
            :max="stage.progressTotal"
            show-progress
          >
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="submitInfo.finished">
        <!-- <h3>Result</h3> -->
        <p>{{ submitInfo.resultText }}</p>
        <ul>
          <li v-for="line in submitInfo.resultLines" :key="line">{{ line }}</li>
        </ul>
      </div>
      <b-button block @click="closeSubmitModal" :disabled="!submitInfo.finished"
        >Close</b-button
      >
    </b-modal>
  </b-container>
</template>

<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig } from "@/util";

const _STAGE_STATES = {
  WAITING: 10,
  IN_PROGRESS: 20,
  FINISHED: 30,
  ERROR: -100,
};

const _resetSubmitInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100,
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server processing",
      },
    },
  };
};

export default {
  name: "emailmanagement",
  data: function () {
    return {
      file: null,
      STAGE_STATES: _STAGE_STATES,
      submitInfo: _resetSubmitInfo(),
    };
  },
  computed: {
    fileIsValid() {
      if (this.file == null) {
        return null;
      }
      return this._validateFile(this.file);
    },
    importCSVFormValid: function () {
      return this.fileIsValid;
    },
  },
  methods: {
    showSubmitCSV: function () {
      this.file = null;
      this.$refs["selectCSVModal"].show();
    },
    closeSubmitModal() {
      this.$refs["csvSubmitModal"].hide();
    },

    _validateFile: function (_file) {
      if (!_file) {
        return false;
      }
      let fparts = _file.name.split(".");
      if (fparts.length <= 1 || fparts[fparts.length - 1] !== "csv") {
        return false;
      }
      return true;
    },
    _checkFiles() {
      if (!this.importCSVFormValid) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "No file set!";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `Please browse or drop a file on the file box.`,
          toastConfig
        );
        return false;
      }
      return true;
    },
    //** Validate the CSV table file with a POST request to the API. */
    async validateCSVFile() {
      if (!this._checkFiles()) {
        return;
      }

      // Setup before showing modal
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["csvSubmitModal"].show();

      // Upload file.
      let fileData = new FormData();
      let _file = this.file;
      fileData.append("file", _file, _file.name);
      let requestConfig = {
        onUploadProgress: (progEvt) => {
          this.submitInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        },
      };
      try {
        let res = await api.postValidateValidationMatrix(
          localStorage.token,
          fileData,
          requestConfig
        );
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;

        // Display results.
        let toastConfig = defaultToastConfig();
        if (res.passed === false) {
          toastConfig.title = "Validation failed";
          toastConfig.variant = "warning"; // maybe 'danger'?
          this.$bvToast.toast(`Validation failed: ${res.message}`, toastConfig);
        } else {
          toastConfig.title = "Validation succesful";
          toastConfig.variant = "success";
          this.$bvToast.toast(
            `Validation succesful: ${res.message}`,
            toastConfig
          );
        }
      } catch (err) {
        console.log(err);
        this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.ERROR;
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;
        this.$store.commit("setLeaveGuard", false);
      }
    },
    async submitCSVFile() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["csvSubmitModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      let _file = this.file;
      // TODO: Error handling missing file here?

      console.log("File validation: ", this._validateFile(_file));

      console.log(_file);
      fileData.append("file", _file, _file.name);

      let requestConfig = {
        onUploadProgress: (progEvt) => {
          this.submitInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        },
      };

      try {
        let res = await api.postSubmitValidationMatrix(
          localStorage.token,
          fileData,
          requestConfig
        );
        console.log(res);
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
      } catch (err) {
        console.log(err);
        this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.ERROR;
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;

        let toastSuccessConfig = defaultToastConfig();
        toastSuccessConfig.title = "Data loaded";
        toastSuccessConfig.variant = "success";
        toastSuccessConfig.autoHideDelay = 800; // Quicker message
        this.$bvToast.toast(
          `Succesfully submitted validation matrix CSV.`,
          toastSuccessConfig
        );

        this.$store.commit("setLeaveGuard", false);
      }
    },
    async fetchCSV() {
      try {
        let blob = await api.getValidationMatrix(localStorage.token);
        console.log(blob);

        let fileUrl = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', blob.name);
        document.body.appendChild(fileLink)

        fileLink.click();

      } catch (err) {
        console.log(err);
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      }
    },
  },
  components: {},
  created() {},
  mounted() {},
};
</script>
